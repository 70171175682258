/* jshint undef: true, unused: true, indent: 4, jquery: true */
/* global document, window, ga:true */

var generatePostData = function() {
    var dataToPost = {};
    $('#main-form :input').each(function(i, el) {
        el = $(el);
        if (el.attr('type') === 'radio' || el.attr('type') === 'checkbox') {
            if (!el.is(':checked')) {
                return;
            }
        }
        dataToPost[el.attr('name')] = el.val();
    });

    return dataToPost;
};

var successResponseCallback = function() {
    window.location = '/thankyou';
    return true;
};

var invalidResponseCallback = function() {
    window.location = '/pending';
    return true;
};

var regexConv = function(elem) {
    return elem.match(/\d+/g)[0];
};

var checkForm = function() {
    var test = true;

    $('.allow-thankyou').each(function(i, el) {
        if (typeof $(el).attr('data-accepted-number') !== 'undefined') {
            if (
                parseInt(regexConv($(el).val()), 10) <
                parseInt($(el).attr('data-accepted-number'), 10)
            ) {
                test = false;
            }
        }

        if (typeof $(el).attr('data-accepted-multi') !== 'undefined') {
            var items = $(el)
                .attr('data-accepted-multi')
                .split('|');
            if (items.includes($(el).val()) === false) {
                test = false;
            }
        }

        if (typeof $(el).attr('data-accepted-string') !== 'undefined') {
            if ($(el).attr('data-accepted-string') !== $(el).val()) {
                test = false;
            }
        }
    });

    return test;
};

var processFinal = function() {
    var postData = generatePostData();

    $('#leadresponse_container').load('/process', postData, function() {
        if (checkForm() === true) {
            successResponseCallback();
        } else {
            invalidResponseCallback();
        }
    });
};

$('#main-form').parsley({
    'data-parsley-validate': true
});

$('#main-form')
    .parsley()
    .on('form:error', function() {
        // reenable button on validation fail
        var thisButton = $('#button-step-1');
        $(thisButton).removeAttr('disabled');
        $(thisButton).html($(thisButton).data('display_text'));
        return true;
    });

$('#main-form')
    .parsley()
    .on('form:success', function() {
        // Form properly validates, finalize
        processFinal();
        return true;
    });

/**
 * SVGs yo
 */
$(document).ready(function() {
    // If Google Analytics code did not run, define an empty function so JS errors don't occur
    if (typeof ga === 'undefined') {
        ga = function() {};
    }

    $('.process-button-click').click(function(e) {
        e.preventDefault();
        $(this).data('display_text', $(this).html());
        $(this).html('Processing<img style="width: 4%" src="//d1fd9shixcplvx.cloudfront.net/sites/af5e01cf/img/processing-ellipses.gif">');
        $(this).attr('disabled', 'disabled');
        var parsleyGroup = 'step-1';
        $('#main-form')
            .parsley()
            .validate({ group: parsleyGroup });
        return false;
    });

    $('input').blur(function() {
        // changing focus on a form element causes it to be validated
        $(this)
            .parsley()
            .validate();
    });
});

// ON Load
$(window).load(function() {
    /**
     * Animated Home Page logo
     */
    //Remove Home page preload class so magic can happen

    $('body').removeClass('preload');

    $('.cssanimations #masthead')
        .stop()
        .queue(function() {
            $(this).addClass('fadeInDown');
        });

    // Form Page
    $('.cssanimations .banner-text')
        .stop()
        .delay(300)
        .queue(function() {
            $(this).addClass('fadeInUp');
        });
    $('.cssanimations .cta-bar')
        .stop()
        .delay(500)
        .queue(function() {
            $(this).addClass('fadeInUp');
        });
    $('.cssanimations .main-footer')
        .stop()
        .delay(700)
        .queue(function() {
            $(this).addClass('fadeInUp');
        });

    $('.cssanimations .form-container')
        .stop()
        .delay(1200)
        .queue(function() {
            $(this).addClass('fadeInRight');
        });

    // Thank You page
    $('.cssanimations .basic-content')
        .stop()
        .delay(300)
        .queue(function() {
            $(this).addClass('fadeInUp');
        });
});
